import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const Contact = () => {
    const [serverState, setServerState] = useState();

    const handleServerResponse = (ok, msg) => {
      setServerState({ok, msg});
    };

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        phone: Yup.string()
          .min(8, 'Invalid phone number')
          .required('Required'),
        subject: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        message: Yup.string()
          .min(25, 'Must be 25 characters or more')
          .required('Required')
      }),
      onSubmit: async (values, actions) => {
        actions.setSubmitting(true);

        try {
          const response = await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            {
              sender: { name: "WebForm CWB Medical", email: "domain@indonesiadefenceservices.com" },
              to: [{ email: "info@citrawirabawana.com", name: "Info" }],
              subject: `Leads from website CWB | ${values.subject}`,
              htmlContent: `<p style="font-weight: 400;">A new inquiry has been sent from PT Citra Wira Bawana Website, please check the detailed information below:</p>
              <ol>
              <li style="font-weight: 400;"><strong>Name: </strong>${values.name}</li>
              <li style="font-weight: 400;"><strong>Phone: </strong>${values.phone}</li>
              <li style="font-weight: 400;"><strong>Email: </strong>${values.email}</li>
              <li style="font-weight: 400;"><strong>Message: </strong>${values.message}</li>
              </ol>
              <p style="font-weight: 400;">Therefore, please kindly respond to the sender of the inquiry in a timely manner. Kindly contact us at<strong>&nbsp;<a href="mailto:info@citrawirabawana.com">info@citrawirabawana.com</a></strong>&nbsp;&nbsp;if you have further questions regarding the inquiry. Thank you.</p>
              <p style="font-weight: 400;"><strong>Best Regards,</strong></p>
              <table style="height: 86px; width: 16.5146%; border-collapse: collapse; background-color: #194f78;" border="1">
              <tbody>
              <tr style="height: 48px;">
              <td style="width: 56px; height: 48px;"><img style="max-width: 100px; height: auto; display: block; margin-left: auto; margin-right: auto;" src="https://cwbmedical.com/assets/images/logo/logo-light.png" alt="CWB Logo" /></td>
              </tr>
              </tbody>
              </table>
              <p><strong>ICT Department of PT. Citra Wira Bawana</strong></p>`
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_SENDINBLUE_API_KEY
              }
            }
          );

          actions.setSubmitting(false);
          actions.resetForm();
          handleServerResponse(true, "Thank you for your message, we will get back to you soon");
        } catch (error) {
          actions.setSubmitting(false);
          console.error(error);
          handleServerResponse(false, "Error sending message");
        }
      },
    });
    return(
          <div className="contact-form--1">
              <div className="container">
                  <div className="row row--35 align-items-start">
                      <div className="col-lg-6 order-2 order-lg-1">
                          <div className="section-title text-left mb--50">
                              <h2 className="title">Contact Us.</h2>
                              <p className="description">Let’s get in touch! We’d love to hear from you.</p>
                          </div>
                          <div className="form-wrapper">
                            <form onSubmit={formik.handleSubmit}>
                              <label htmlFor="item01">
                                  <input
                                      type="text"
                                      name="name"
                                      id="item01"
                                      onChange={formik.handleChange}
                                      value={formik.values.name}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Name *"
                                  />
                                {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                              </label>

                              <label htmlFor="item02">
                                  <input
                                      type="text"
                                      name="email"
                                      id="item02"
                                      onChange={formik.handleChange}
                                      value={formik.values.email}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your email *"
                                  />
                                {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                              </label>

                              <label htmlFor="item03">
                                  <input
                                      type="text"
                                      name="phone"
                                      id="item03"
                                      onChange={formik.handleChange}
                                      value={formik.values.phone}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Phone Number *"
                                  />
                                {formik.touched.phone && formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null}
                              </label>

                              <label htmlFor="item04">
                                  <input
                                      type="text"
                                      name="subject"
                                      id="item04"
                                      onChange={formik.handleChange}
                                      value={formik.values.subject}
                                      onBlur={formik.handleBlur}
                                      placeholder="Write a Subject"
                                  />
                                {formik.touched.subject && formik.errors.subject ? <div className="error">{formik.errors.subject}</div> : null}
                              </label>
                              <label htmlFor="item05">
                                  <textarea
                                      type="text"
                                      id="item05"
                                      name="message"
                                      onChange={formik.handleChange}
                                      value={formik.values.message}
                                      onBlur={formik.handleBlur}
                                      placeholder="Your Message"
                                  />
                                {formik.touched.message && formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
                              </label>
                              <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" disabled={formik.isSubmitting}>
                                {
                                  formik.isSubmitting
                                    ?
                                    <ClipLoader
                                      size={28}
                                      color={"#fff"}
                                    />
                                  :
                                    <>Submit</>
                                }
                              </button>

                              {serverState && (
                                <p className={!serverState.ok ? "error ptb-10" : "ptb-10"}>
                                  {serverState.msg}
                                </p>
                              )}
                            </form>
                          </div>
                      </div>
                      <div className="col-lg-6 order-1 order-lg-2">
                          <div className="thumbnail mb_md--30 mb_sm--30">
                              <img src="/assets/images/about/overlayColor.png" alt="CWB"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
}
export default Contact;
